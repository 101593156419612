<template>
  <div class="serviceMain">
    <div class="searchBar">
      <div class="tittle"> 我的存证 </div>
      <div class="rightForm">
        <el-input
          v-model="form.subject"
          placeholder="请输入作品名称"
        ></el-input>
        <el-date-picker
          v-model="form.date"
           value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
        <el-button @click="fetchData">查询</el-button>
        <!-- <router-link :to="{path:'/ExistingEvidence/InputForm'}">
            <el-button type="primary">新增存证</el-button>
        </router-link> -->
      </div>
    </div>

    <el-table
      :data="tableData"
      :cell-class-name="tabelCellClass"
      style="width: 100%"
      @row-click="onTabelRowClick"
    >
      <el-table-column
        prop="subject"
        label="存证名称"
        width="160"
         show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="orderNo"
        label="订单号"
        width="170"
      >
      </el-table-column>
      <el-table-column
        prop="num"
        label="数量"
        sortable
        width="80"
      >
      </el-table-column>
      <el-table-column
        prop="createTimeText"
        label="创建时间"
        sortable
        width="170"
      >
        <!-- eslint-disable-next-line -->
       <template slot="header" slot-scope="scope">
          <div class="createTimeHeader">
            <span>创建时间</span>
            <a-tooltip>
              <template slot="title">
                prompt text
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="statusText"
        label="状态"
        width="90"
      >
        <template slot-scope="scope">
          <span
            class="stausCell"
            :class="{'stausCell1':scope.row.status=='10','stausCell2':scope.row.status=='11','stausCell3':scope.row.status=='21'}"
          >
            {{scope.row.statusText}}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="certificate"
        label="证书"
        width="150"
      >
        <template slot-scope="scope">
          <div class="signList">
            <span
              :class="{'graySpan':scope.row.status!='21','greenSpan':scope.row.status=='21'}"
            >
              存
              <i
                class="redBag"
                v-if="scope.row.status=='21'"
              > 1 </i>
            </span>

             <span
              class="graySpan"
            >
              取
           
            </span>

             <span
                 class="graySpan"
            >
              公
           
            </span>
          </div>

        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="100"
        fixed="right"
      >
        <!-- eslint-disable-next-line -->
        <template slot-scope="scope">
          <!-- {{scope.row.status}} -->
       
               <!-- <a-dropdown
            :trigger="['click']"
            placement="bottomLeft"
            overlayClassName="moveLeft"
          >
            <a @click.stop="e => e.preventDefault()">
              <a-icon type="more" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                 <router-link :to="{path:'/ExistingEvidence/InputForm'}"> 重新存证 </router-link>  
              </a-menu-item>
              <a-menu-item>
               <router-link :to="{path:'/Register'}"> 创建登记 </router-link>  
              </a-menu-item>
              <a-menu-item>
                  <router-link :to="{path:'/Monitor/InputForm'}"> 创建监测 </router-link>  
              </a-menu-item>
               <a-menu-item>
                  <router-link :to="{path:'/ObtainEvidence/InputForm'}"> 创建取证 </router-link>  
              </a-menu-item>
              <a-menu-item>
                <router-link :to="{path:'/OnSale/InputForm'}"> 合约挂卖 </router-link>
              </a-menu-item>
            </a-menu>
          </a-dropdown> -->

          <a-icon type="right" class="ml12 gray" />
       
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="pagination.total"
      :current-page.sync="pagination.page"
      :page-size.sync="pagination.pageSize"
      @size-change="sizeChange"
      @current-change="currentChange"
    >
    </el-pagination>

  </div>
</template>

<script>
import {queryExistOrders} from '@/api/EvidenceService'
export default {
  name: 'ExistingEvidence',

  data () {
    return {
      form: {
        subject: null,
        date: null
      },
      pagination: {
        page: 1,
        pageSize: 10,
        total:0
      },
           tableData: []
      //      tableData:[{
      //   name: '《鄂菜大全》《鄂菜大全》《鄂菜大全》',
      //   orderNo: '109000990099998',
      //   num: '3',
      //   date: '2019-12-23 14:07',
      //   typeDesc: "网页截图",
      //    status: 1, // 1、无结果  2、处理中  3、已完成
      //   statusDesc: "无结果",
      //   certificate: [
      //     {
      //       status: 1, // 1、处理中  2、完成
      //       value: 1, // 1、存   2、取   3、公
      //       label: '存'
      //     },
      //   ],

      // }, {
      //   name: '《鄂菜大全》',
      //   orderNo: '109000990099998',
      //   num: '3',
      //   date: '2019-12-23 14:07',
      //    typeDesc: "视频",
      //     status: 2, // 1、待付款  2、处理中  3、已完成
      //   statusDesc: "处理中",
      //   certificate: [
      //     {
      //       status: 2, // 1、处理中  2、完成
      //       value: 1, // 1、存   2、取   3、公
      //       label: '存'
      //     },
      //     {
      //       status: 2, // 1、处理中  2、完成
      //       value: 2, // 1、存   2、取   3、公
      //       label: '取',
      //       num: 10
      //     },
      //     {
      //       status: 2, // 1、处理中  2、完成
      //       value: 3, // 1、存   2、取   3、公
      //       label: '公'
      //     },
      //   ],

      // }]
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    onTabelRowClick(row){
      this.$router.push({
        path: "/ExistingEvidence/Detail",
        query: {orderNo:row.orderNo}
      });
    },
    tabelCellClass ({ row, column, rowIndex, columnIndex }) {
      if (column.property == 'certificate') {
        return 'certificateCell'
      }
    },
    getStatus(code){
        switch(code){
          case '10':return '待付款';
          case '11':return '待处理';
          case '21':return '已完成';
          case '31':return '失败';
        }
    },
    fetchData (param) {
     queryExistOrders(this.pagination.page,this.pagination.pageSize,this.form).then(({data})=>{
          console.log("我的存证列表：",data);
          for (let i = 0; i < data.list.length; i++) {
            const element = data.list[i];
             element.num=element.items.length;
             element.statusText=this.getStatus(element.status);
              element.createTimeText= this.dayjs(element.createTime*1000).format('YYYY-MM-DD HH:mm:ss ');
      // this.orderInfo.updateTimeText= this.dayjs(this.orderInfo.updateTime*1000).format('YYYY-MM-DD HH:mm:ss ');
          }
          this.tableData=data.list;
          this.pagination.total=data.total*1;
        });
    },
    sizeChange (pageSize) {
      this.pageSize = pageSize
      this.pageNum = 1
      this.fetchData()
    },
    currentChange (pageNum) {
      // 更新表格数据
      this.fetchData()
    },
  }
}
</script>

<style lang='scss' scoped>
@import '../../../assets/css/mine.scss';
</style>
